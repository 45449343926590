.flux-promo-item-link-large_device {
    border-radius: 3px;
    max-width: 320px;
    min-width: 320px;
    max-height: 446px;
    min-height: 446px;
}

.flux-promo-item-link-large_device:hover {
    transition: all 0.1s;
    box-shadow: 0px 0px 6px 1px rgba( 0, 0, 0, .1 );
}

.flux-promo-item-link-small_device {
    display: none;
    max-height: 349px;
    min-height: 349px;
    margin: 0.5px;
} 

.mob-bloc-layout {
    max-width: 186px;
    min-width: 186px;
}

.mob-slider-layout {
    max-width: 244px;
    min-width: 244px;
}

.flux-promo-item-reduction {
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    top: 3px;
    right: 0;
    text-align: center;
    font-weight: 700;
    background-color: #ffed6b;
    border-radius: 50%;
}
  
.flux-promo-item-title {
    overflow: hidden;
    line-height: 2rem;
}
      
.flux-promo-item-title-small_device {
    line-height: 1.6rem;
    height: 52px;
}

@media all and (max-width: 1400px) {
    .flux-promo-item-link-large_device {
        margin: 0.5rem;
    }
}

@media all and (max-width: 767.99px) {
    .flux-promo-item-link-large_device {
        display: none;
    }
    .flux-promo-item-link-small_device {
        display: block;
    }
}

@media all and (max-width: 375px) {
    .mob-bloc-layout {
        max-width: 178px;
        min-width: 178px;
    } 
    .promo-price-economy {
        font-size: 1rem;
    }
}

@media all and (max-width: 358px) {
    .flux-promo-item-link-small_device {
        padding-left: 5px;
        padding-right: 5px;
        margin: 0;
    } 
    .mob-bloc-layout {
        max-width: 158px;
        min-width: 158px;
    }
    .flux-promo-item-clubR-small_device {
        padding-top: 8px;
    }
    .flux-promo-item-clubR-small_device .red-rkt .main-black {
        font-size: 9px;
    }
    .promo-price-economy {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 7px;
    }
}

