.cta-btnMore {
    border-radius: 4px;
    background-color: transparent;
    color: black;
    border: 1px black solid;
    font-size: 1.6rem;
    line-height: 1.25;
    text-align: center;
    transition: all 0.3s;
    display: block;
    padding: 1.4rem 2rem;
    text-decoration: none;
    max-width: 280px;
}
.cta-btnMore:hover {
    background-color: black;
    color: #fff;
    text-decoration: none;
}