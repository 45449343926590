/*Mob*/
.helper_padding_12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .helper_shadow {
    -webkit-box-shadow: inset 0 1px 0 0 #ddd, 0 -1px 4px 0 rgba(74,74,74,.2);
    box-shadow: inset 0 1px 0 0 #ddd, 0 -1px 4px 0 rgba(74,74,74,.2);
  }

  .ReactModalPortal {
    z-index: 9999;
    overflow: hidden;
  }
  .ReactModal__Overlay {
    z-index: 9999;
    overflow: hidden;
  }
  
  .filterMob_button {
    width: 200px;
    min-width: auto;
  }
  
  .filterMob_interface {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ebebeb;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-left: 0;
  }
  
  .filterMob_interface-animation {
    animation-duration: 0.4s;
    animation-name: slidein;
  }
  
  .filterMob_interface .text {
    color: #4d4d4d;
  }
  
  .backToResult {
    top: -11px;
  }
  
  .container-mobFilterChoiceBox {
    background-color: #ebebeb;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .advertType--cta {
    padding: 14px 0;
  }
  
  @media screen and (max-width:576px) {
    .container-filters {
      margin-top: 6rem;
    }
    .box-choices {
      top: 1rem;
    }
    .checkbox-categorie {
      border-bottom: 1px solid #eee;
    }
    .titleFilter {
      min-height: 56px;
      max-height: 56px;
    }
    .filter--sous-catégories {
      margin-top: 0;
      border-top: 0;
    }
  }
  
  @keyframes slidein {
    from {
      margin-top: 100%;
    }
  
    to {
      margin-top: 0%;
    }
  }
  