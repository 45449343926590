.flux-promo-bloc-clubR {
    background-image: url(https://pmcdn.staticpmrk.com/visuels/canopy-commons/BG_flux_desk.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    border: 1px solid #ddd;
}

.flux-promo-item-clubR-large_device img {
    height: 14px;
}

.flux-promo-item-clubR-small_device img {
    height: 12px;
}

.mob-slider-layout .flux-promo-item-clubR-small_device {
    justify-content: center;
}