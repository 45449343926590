.chevron {
    width: 10px;
    height: 10px;
}
  
.topChevron {
    transform: rotate(180deg);
}
  
.bottomChevron {
    transform: rotate(0deg);
}
  
.rightChevron {
    transform: rotate(90deg);
}
  
.leftChevron {
    transform: rotate(-90deg);
}
  