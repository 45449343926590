.skew10 {
    transform: skew(10deg);
    -webkit-transform: skew(10deg);
    -moz-transform: skew(10deg);
}

.flux-promo-item-sticker {
    min-height: 24px;
    max-height: 24px;
    width: 120px;
    border-radius: 3px;
    transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
}

.flux-promo-item-sticker-c2c {
    min-height: 24px;
    max-height: 24px;
}

.flux-promo-item-sticker-refurb {
    min-height: 24px;
    max-height: 24px;
    width: 200px;
    border-radius: 3px;
    background-color: #009800;
    transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
}
.green-c2c {
    color: #009800;
}

.flux-promo-icon {
    width: 12px;
}

.icon-heart {
    padding-top: 2px;
    margin-left: 5px;
}

.flux-promo-icon_c2c {
    width: 12px;
}

@media all and (max-width:768px) {
    .flux-promo-item-sticker {
        min-height: 18px;
        max-height: 18px;
        width: 90px;
        margin-top: 1rem;
    }
    .flux-promo-item-sticker-refurb {
        min-height: 18px;
        max-height: 18px;
        width: 170px;
        margin-top: 1rem;
    }
    .mob-bloc-layout .flux-promo-item-sticker-refurb {
        left: -2px;
    }

    .flux-promo-item-sticker-c2c {
        min-height: 18px;
        max-height: 18px;
        margin-top: 1rem;
    }

    .flux-promo-item-sticker-text {
        font-size: 10px;
    }
    .icon-sticker {
        width: 8px;
    }
}
@media all and (max-width: 375px) {
    .mob-bloc-layout .flux-promo-item-sticker-refurb {
        left: -7px;
    }
}
@media all and (max-width: 358px) {
    .mob-bloc-layout .flux-promo-item-sticker-refurb {
        min-height: 28px;
        max-height: 28px;
        margin-top: O;
        left: 0px;
        width: 105px;
    }
}