.container-checkbox {
/* for IE */
  width: 15px;
  height: 15px;
/* end for IE */
  width: 1.5rem;
  height: 1.5rem;
}

.container-checkbox .checkmark {
    border-radius: 0.3rem;
    height: 1.3rem;
    width: 1.3rem;
}

.container-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: calc(50% - 0.3rem);
    top: calc(50% - 0.7rem);
    width: 0.5rem;
    height: 0.8rem;
    border: 1px solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.container-checkbox input:checked ~ .checkmark {
    background: black;
}

.container-checkbox input:checked ~ .checkmark::after {
    display: block;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}