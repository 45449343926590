.input-price--container {
    height: 40px;
}

/*Chrome, Safari, Edge, Opera*/
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (max-width:576px) {
    .input-price--container {
        height: 48px;
    }
}